/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
    max-width: 100vw;
    max-height: 100vh;
    background-color: #f0f0f0;
}

app-root {
    max-width: 100%;
    max-height: 100%;
}

:root {
    --black: #000;
    --white: #fff;

    // --kuv-bg-options: var(--blue-grey-800);
    // --kuv-cl-options: var(--white);

    // --kuv-bg-children: var(--blue-grey-700);
    // --kuv-cl-children: var(--white);

    // --kuv-bg-hover: var(--blue-grey);
    // --kuv-cl-hover: var(--white);

    --kuv-bg-active: white;
    // --kuv-cl-active: var(--white);

    // --kuv-bg-logo: var(--blue-grey-900);
    // --kuv-cl-logo: var(--white);

    // --kuv-bg-toggle: var(--blue-grey-500);
    // --kuv-cl-toggle: var(--white);

    // --kuv-cl-icon: var(--white);

    // --kuv-table-bg-body-1: white;
    // --kuv-table-bg-head: #343a40;
    // --kuv-table-cl-head: white;
    // --kuv-table-bg-body-2: white;
}

kuv-compact-sidebar .option.active {
    background-color: #222222 !important;
    border-color: #222222;
}

kuv-compact-sidebar .option .text {
    color: white !important;
}

.k-cell.k-action-cell {
    display: flex;

    div:not(:last-child) {
        margin-right: .5rem;
    }
}

#ngx-kuv-sidebar-option-text {
    line-height: 1.1;
}

div#kuv-sidebar .main-container .options-container .icon {
    color: var(--kuv-cl-icon) !important;
}

#filtros {
    .btn {
        border-color: #DCC140 !important;
        background-color: #DCC140 !important;
        color: black !important;
    }

    .btn:hover {
        background-color: #DCC140 !important;
        border-color: #DCC140 !important;
    }
}

.ng-dropdown-panel {
    z-index: 100000 !important;
}

.label-form {
    color: #555555;
}